<template>
  <div class="beavercontainer">
    <Header brand="Beaverops" />
    <p class="lead mb-0">made in &#127468;&#127479;</p>
    <a
      href="https://ehaircut.herokuapp.com"
      target="_blank"
      rel="noopener"
      class="btn btn-outline-orange mx-2"
      >ehaircut</a
    >
    <a
      href="https://www.mathflare.xyz"
      target="_blank"
      rel="noopener"
      class="btn btn-outline-orange mx-2"
      >MathFlare</a
    >
  </div>
</template>

<script>
import Header from "./components/Header.vue";

export default {
  name: "App",
  components: {
    Header,
  },
};
</script>
