<template>
  <h1 class="display-1 brand">&#182;{{ brand }}</h1>
</template>

<script>
export default {
  name: "Header",
  props: {
    brand: String,
  },
};
</script>
